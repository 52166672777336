/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(179, 179, 179); 
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777777; 
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777777; 
}